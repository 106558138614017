import './SelectCost.css';
import {Button, Grid, Popup} from "semantic-ui-react";
import CustomAccordion from "../../../../shared/CustomAccordion/CustomAccordion";
import CustomCheckbox from "../../../../shared/CustomCheckbox/CustomCheckbox";

export default function SelectCost({onComplete, onClickBack, data, setData}) {
    let summ = (()=>{let result = 0; data.calculation.MainWorks.map(w=>result+=w.Price); return result;})() +
        (()=>{let result = 0; data.calculation.MainItems.map(w=>result+=w.Price); return result;})() +
        (()=>{let result = 0; data.additionalWorks.filter(x=>x.added).map(w=>result+=w.Price); return result;})();

    // let additionWorkSelector = () => {
    //     return <>
    //         <Grid.Row>
    //             <Grid.Column width={10} style={{textAlign: 'left'}}>Дополнительные работы по пробегу или по времени</Grid.Column>
    //         </Grid.Row>
    //         <table>
    //         <tbody>
    //         {data.additionalWorks.map(x=><tr>
    //             <td>
    //                 <CustomCheckbox
    //                     value={x.added}
    //                     onChange={()=>{
    //                         setData(dataOld => {
    //                             let temp = dataOld.map(z=>z);
    //                             for (let a in data) {
    //                                 temp[a] = data[a];
    //                             }
    //                             let targetElement = temp.additionalWorks.filter(y=>x.WorkId == y.WorkId)[0];
    //                             targetElement.added = !targetElement.added;
    //                             return temp;
    //                         });
    //                     }}
    //                     label={x.Descr}
    //                 />
    //             </td>
    //             <td /*style={{paddingRight: '69px'}}*/>{x.Price.toLocaleString(undefined, {
    //                 maximumFractionDigits: 2
    //             }) + '\u00A0₽'}</td>
    //         </tr>)}
    //         </tbody>
    //     </table></>;
    // };

    return <div className={'select-cost-page'}>
        <CustomAccordion defaultOpenedSections={[]} sections={[
            {
                title: 'Работы по техническому обслуживанию',
                label: ((()=>{let result = 0; data.calculation.MainWorks.map(w=>result+=w.Price); return result;})().toLocaleString(undefined, {
                    maximumFractionDigits: 2
                })) + '\u00A0₽',
                content: <>
                    <table>
                        <thead>
                        <tr>
                            <th>Наименование</th>
                        </tr>
                        </thead>
                        <tbody>
                            {data.calculation.MainWorks.map(x=><tr>
                                <td style={{fontWeight: '400'}}>{x.Descr}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </>
            },
            {
                title: 'Запасные части для технического обслуживания',
                label: ((()=>{let result = 0; data.calculation.MainItems.map(w=>result+=w.Price); return result;})().toLocaleString(undefined, {
                    maximumFractionDigits: 2
                })) + '\u00A0₽',
                content: <>
                    <table>
                        <thead>
                        <tr>
                            <th>Наименование</th>
                            <th>Цена за ед.</th>
                            <th>Кол-во</th>
                            <th>Стоимость</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.calculation.MainItems.map(part => <tr>
                            <td style={{maxWidth: '494px', overflow: 'hidden'}}>{part.Descr}</td>
                            <td style={{paddingRight: '30px'}}>{(part.Price == null ? '' : (part.Price / part.Qnt).toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            }) + '\u00A0₽')}</td>
                            <td style={{textAlign: 'center'}}>{part.Qnt}</td>
                            <td /*style={{paddingRight: '69px', paddingLeft: '55px',}}*/>{(part.Price == null ? '' : (part.Price).toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            }) + '\u00A0₽')}</td>
                        </tr>)}
                        </tbody>
                    </table>
                </>
            },
            {
                title: 'Дополнительные работы по пробегу или по времени',
                label: ((()=>{let result = 0; data.additionalWorks.filter(x=>x.added).map(w=>result+=w.Price); return result;})().toLocaleString(undefined, {
                    maximumFractionDigits: 2
                })) + '\u00A0₽',
                content: <>
                    <table>
                        <thead>
                        <tr>
                            <th>Наименование</th>
                            <th>Стоимость</th>
                        </tr>
                        </thead>

                        {data.additionalWorks.length > 0 ? <tbody>{data.additionalWorks.map(x=><tr>
                            <td>
                                <CustomCheckbox
                                    value={x.added}
                                    onChange={()=>{
                                        setData(dataOld => {
                                            let temp = dataOld.map(z=>z);
                                            for (let a in data) {
                                                temp[a] = data[a];
                                            }
                                            let targetElement = temp.additionalWorks.filter(y=>x.WorkId == y.WorkId)[0];
                                            targetElement.added = !targetElement.added;
                                            return temp;
                                        });
                                    }}
                                    label={<>{x.Descr}{x.Notes !== '' ? <div style={{color: '#8d8d8d', fontSize: '14px', marginTop: '5px'}}>{x.Notes}</div> : ''}</>}
                                /></td>
                            <td /*style={{paddingRight: '69px', paddingLeft: '55px'}}*/>{x.Price.toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            }) + '\u00A0₽'}</td>
                        </tr>)}</tbody> : <tbody>Не выбрано ни одной дополнительной работы</tbody>}
                    </table>
                </>
            },
        ]}/>
        <div className={'for-desktop'}>
            <Grid className={'total-table'}>
                <Grid.Row>
                    <Grid.Column width={12}>Итоговая стоимость ТО (с учетом работ и материалов):</Grid.Column>
                    <Grid.Column width={3} style={{paddingRight: '23px'}}><b style={{fontSize: '24px'}}>{summ.toLocaleString(undefined, {
                        maximumFractionDigits: 2
                    })}&nbsp;₽&nbsp;<Popup trigger={<span style={{cursor: 'default'}}>*</span>}>Стоимость работы может измениться в зависимости от технического состояния Вашего автомобиля</Popup></b></Grid.Column>
                </Grid.Row>
                {data.calculation.MarketingActions.length > 0 ? <Grid.Row style={{marginTop: '25px'}}>
                    <Grid.Column width={12}><b>Возможные дополнительные скидки и акции</b></Grid.Column>
                </Grid.Row>: ''}
                {data.calculation.MarketingActions.map(marketingAction => <Grid.Row>
                    <Grid.Column width={12}>{marketingAction.Descr}</Grid.Column>
                    <Grid.Column width={3} style={{paddingRight: '25px'}}>{marketingAction.DiscountPct}%</Grid.Column>
                </Grid.Row>)}
            </Grid>
        </div>
        <div className={'for-mobile'}>
            <Grid className={'total-table'}>
                <Grid.Row>
                    <Grid.Column width={10}>Итоговая стоимость ТО (с учетом работ и материалов):</Grid.Column>
                    <Grid.Column width={5} style={{paddingRight: '23px'}}><b style={{fontSize: '24px'}}>{summ.toLocaleString(undefined, {
                        maximumFractionDigits: 2
                    })}&nbsp;₽&nbsp;<Popup trigger={<span style={{cursor: 'default'}}>*</span>}>Стоимость работы может измениться в зависимости от тезнического состояния Вашего автомобиля</Popup></b></Grid.Column>
                </Grid.Row>
                {data.calculation.MarketingActions.length > 0 ? <Grid.Row style={{marginTop: '25px'}}>
                    <Grid.Column width={12}><b>Возможные дополнительные скидки и акции</b></Grid.Column>
                </Grid.Row> : ''}
                {data.calculation.MarketingActions.map(marketingAction => <Grid.Row>
                    <Grid.Column width={12}>{marketingAction.Descr}</Grid.Column>
                    <Grid.Column width={3} style={{paddingRight: '25px'}}>{marketingAction.DiscountPct}%</Grid.Column>
                </Grid.Row>)}
            </Grid>
        </div>
        <Grid style={{marginTop: '30px'}}>
            <Grid.Row className={'actions-container'}>
                <Grid.Column width={8}>
                    <Button onClick={onClickBack}>Вернуться</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Button onClick={()=>{
                        setData(data => {
                            data.filter(x=> x.name === 'money')[0].subTitle = summ.toLocaleString(undefined, {
                                maximumFractionDigits: 2
                            }) +'\u00A0₽';
                            data.totalPrice = summ;
                            return data;
                        });
                        onComplete();
                    }}>Продолжить</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>;
}
