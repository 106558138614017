import './SelectEngine.css';
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CarItem from "../../../../shared/CarItem/CarItem";
import CustomDropdown from "../../../../shared/CustomDropdown/CustomDropdown";
import CustomSlider from "../../../../shared/CustomSlider/CustomSlider";
import {pluralize} from "numeralize-ru";
import {api} from "../../../../utils/api";

export default function SelectEngine({onComplete, data, setData, onClickBack, dataFromLk}) {
    let carName = data.filter(x=> x.name === 'car')[0].subTitle;
    let imageUrl = data.filter(x=> x.name === 'car')[0].imageUrl;
    let carColor = data.filter(x=> x.name === 'car')[0].color;

    let [engine, setEngine] = useState(data.filter(x=> x.name === 'engine')[0].type);
    let [age, setAge] = useState(data.filter(x=> x.name === 'engine')[0].age);
    let [mileage, setMileage] = useState(data.filter(x=> x.name === 'engine')[0].mileage);
    let [maintenance, setMaintenance] = useState(data.filter(x=> x.name === 'engine')[0].maintenance);

    useEffect(()=>{
        if(dataFromLk) {
            let versionId = dataFromLk.cars.filter(x=>x.vin === data.carVin)[0].versionId;
            setEngine(versionId);
            setDataInPage(versionId, 'type');
            api()
                .getMaintenanceInfo(data.filter(x => x.name === 'car')[0].familyId, versionId)
                .then(resp => {
                    setDataInPage(resp, 'maintenances');
                });
        }else {
            if(data.filter(x=> x.name === 'engine')[0].type){
                api()
                    .getMaintenanceInfo(data.filter(x => x.name === 'car')[0].familyId, data.filter(x=> x.name === 'engine')[0].type)
                    .then(resp => {
                        setDataInPage(resp, 'maintenances');
                    });
            }
        }
    }, []);

    useEffect(()=>{
        if(dataFromLk) {}
        else{
            setEngine(data.filter(x=> x.name === 'engine')[0].type);
        }
        setAge(data.filter(x=> x.name === 'engine')[0].age);
        setMileage(data.filter(x=> x.name === 'engine')[0].mileage);
        setMaintenance(data.filter(x=> x.name === 'engine')[0].maintenance);
    }, [data]);

    let setDataInPage = (val, name)=>{
        let temp = data.map(x=>x);
        //debugger
        temp.filter(x=> x.name === 'engine')[0][name] = val;
        for (let a in data) {
            temp[a] = data[a];
        }
        setData(temp);
    };

    let trySetMaintenanceDropdown = (ageVal, mileageVal) => {
        let variants = (data.filter(x=> x.name === 'engine')[0].maintenances.map(x=> ({
            id: x.Descr,
            name: x.Descr,
            serviceBookId: x.ServiceBookId,
            serviceBookColumnId: x.ServiceBookColumnId,
        })));
        let topMileageVal = mileageVal /10;
        let topAgeVal = ageVal;

        if(topMileageVal  > topAgeVal) {
            topAgeVal = topMileageVal;
        }else{
            if(topMileageVal  < topAgeVal){
                topMileageVal = topAgeVal;
            }
        }

        let descr = '(' + ((topMileageVal *10) *1000)  + '/' + ((topAgeVal) === 0 ? 2 : topAgeVal * 12) + ')';
        let val = variants.filter(x=> x.name.indexOf(descr) !== -1)[0];
        if(val !== undefined){
            setDataInPage(val.id, 'maintenance');
            setDataInPage(val.serviceBookId, 'serviceBookId');
            setDataInPage(val.serviceBookColumnId, 'serviceBookColumnId');
        }
    };

    let renderControls = () => {
        return <div>
            <CustomDropdown value={engine}
                            disabled={dataFromLk ?? false}
                            onChange={(val) => {
                                setDataInPage(val, 'type');
                                api()
                                    .getMaintenanceInfo(data.filter(x => x.name === 'car')[0].familyId, val)
                                    .then(resp => {
                                        setDataInPage(resp, 'maintenances');
                                    });
                            }}
                            label={<span><span style={{color: '#C7001F'}}>*</span> Комплектация</span>}
                            options={(data.filter(x => x.name === 'car')[0].carVersions.map(x => ({
                                id: x.VersionId,
                                name: x.Descr /*+ ' ' + x.TransmissionDescr + ' ' + x.EngineDescr*/
                            })))}/>
            {engine == '' ? '' : <div className={"version-description"}>
                <img src={'/img/engine-icon.svg'}/> {data.filter(x => x.name === 'car')[0].carVersions.filter(x=>x.VersionId == engine)[0].EngineDescr}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img src={'/img/transmission-icon.svg'}/> {data.filter(x => x.name === 'car')[0].carVersions.filter(x=>x.VersionId == engine)[0].TransmissionDescr}
            </div>}

            <div className={'sliders-container'}>
                <CustomSlider
                    label={'Срок эксплуатации (годы)'}
                    initValue={age}
                    maxValue={10}
                    step={1}
                    onChange={(val) => {
                        setDataInPage(val, 'age');
                        trySetMaintenanceDropdown(val, mileage);
                    }}
                />
                <CustomSlider
                    label={'Пробег (тыс.км)'}
                    initValue={mileage}
                    maxValue={150}
                    step={10}
                    onChange={(val) => {
                        setDataInPage(val, 'mileage');
                        trySetMaintenanceDropdown(age, val);
                    }}
                />
                {engine === '' ? <div className={'sliders-placeholder'}/> : ''}
            </div>
            {data.filter(x => x.name === 'engine').length > 0 &&
            data.filter(x => x.name === 'engine')[0].maintenances !== undefined ? <>
                    <div style={{marginTop: '25px', marginBottom: '15px'}}>Выберите ТО</div>
                    <CustomDropdown value={maintenance}
                                    onChange={(val) => {
                                        setDataInPage(val, 'maintenance');
                                        let temp = data.filter(x => x.name === 'engine')[0].maintenances.filter(x => x.Descr == val)[0]
                                        setDataInPage(temp.ServiceBookId, 'serviceBookId');
                                        setDataInPage(temp.ServiceBookColumnId, 'serviceBookColumnId');
                                    }}
                                    label={<span><span style={{color: '#C7001F'}}>*</span> ТО</span>}
                                    options={(data.filter(x => x.name === 'engine')[0].maintenances.map(x => ({
                                        id: x.Descr,
                                        name: x.Descr
                                    })))}/></>
                 : ''}
        </div>;
    }

    return <div className={'select-engine-page'}>
        <Grid>
            <Grid.Row style={{marginBottom: '14px'}} className={'for-desktop'}>
                <Grid.Column width={10} style={{paddingTop: '30px'}}>
                    {renderControls()}
                </Grid.Column>
                <Grid.Column width={6} style={{paddingTop: '10px'}}>
                    <CarItem
                        name={carName}
                        color={carColor}
                        isMarked={true}
                        imageUrl={imageUrl}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingTop: '50px'}}>
                <Grid.Column width={16}>
                    <div className={'for-mobile'}>
                        <CarItem
                            name={carName}
                            color={carColor}
                            isMarked={true}
                            imageUrl={imageUrl}
                        />
                        <div style={{paddingRight: '20px'}}>
                            {renderControls()}
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={'actions-container'}>
                <Grid.Column width={8}>
                    <Button onClick={onClickBack}>Вернуться</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    {maintenance !== '' && maintenance !== undefined ?
                        <Button onClick={() => {
                            setData(data => {
                                data.filter(x => x.name === 'engine')[0].subTitle = age + ' ' + pluralize(age, 'год', 'года', 'лет') + ', ' + mileage + ' тыс.км';
                                return data;
                            });
                            onComplete();
                        }}>Продолжить</Button> : ''}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>;
}
