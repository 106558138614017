import './CustomTextInput.css';
import InputMask from "react-input-mask";

export default function CustomTextInput({isRequired, isError, placeholder, value, onChange, onFocus, mask, pattern, disabled}) {
    return <div className={'custom-input-container'}>
        {isRequired ? <span className={'required-marker'}>*</span> : ''}
        {mask == undefined ? <input
            disabled={disabled ?? false}
            className={'custom-input' + (isRequired ? ' is-required': '')
                + (isError ? ' is-error': '')}
            value={value}
            placeholder={placeholder}
            onChange={(e)=>{
                onChange(e.target.value);
            }}
            onFocus={onFocus}
        /> : <InputMask
            className={'custom-input' + (isRequired ? ' is-required': '')
                + (isError ? ' is-error': '')}
            mask={mask}
            placeholder={'Телефон'}
            pattern={pattern}
            value={value}
            onChange={(e)=>{
                onChange(e.target.value);
            }}
            onFocus={onFocus}
        />}
  </div>;
}
