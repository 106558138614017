import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Menu from "./menu/Menu";
import ServiceCalculator from "./pages/ServiceCalculator/ServiceCalculator";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
    return (
      <BrowserRouter>
          <Menu/>
          <Routes>
              <Route path="/" element={<ServiceCalculator/>}/>
          </Routes>
          <ToastContainer />
      </BrowserRouter>
    );
}
