import './CustomDropdown.css';
import { Dropdown, } from "semantic-ui-react";
import {useRef} from "react";

export default function CustomDropdown({label, options, value, onChange, iconIfHaveValue, disabled}) {
  let dropdownRef = useRef();
  return <div className={'custom-dropdown-container' + (value !== null ? ' with-value' : '') + (value !== null && iconIfHaveValue !== undefined && iconIfHaveValue !== null ? ' with-icon' : '')}>
    <Dropdown
        disabled={disabled ?? false}
        noResultsMessage={'Не найдено'}
        ref={dropdownRef}
        value={value}
        placeholder={label}
        onChange={(e, data) => {
          onChange(data.value);
        }}
        search
        selection
        options={options.map(x=> ({key: x.id, text: x.name, value: x.id}))} />

    {options.length > 1 ? <img onClick={(e)=>{
      dropdownRef.current.handleClick(e);
    }} className={'dropdown-chevron'} src={'/img/dropdown-chevron.svg'}/> : ''}
    {value !== null && iconIfHaveValue !== undefined && iconIfHaveValue !== null ? <img onClick={(e)=>{
      dropdownRef.current.handleClick(e);
    }} className={'addition-icon'} src={iconIfHaveValue}/> : ''}
  </div>;
}
