import './CustomAccordion.css';
import {useState} from "react";

export default function CustomAccordion({sections, defaultOpenedSections}) {
    let [openedItems, setOpenedItems] = useState(defaultOpenedSections ?? []);
    return <div className={'custom-accordion-container'}>
        {sections.map((section, index) => <div key={section.title + index} className={'custom-accordion-item'}>
            <div className={'custom-accordion-header' + (openedItems.includes(index) ? ' active' : '')} onClick={()=>{
                let temp = openedItems.map(x=>x);
                if(temp.includes(index))
                    temp = temp.filter(x=> x !== index);
                else
                    temp.push(index);
                setOpenedItems(temp);
            }}>
                {section.title}
                <div className={'label'}>{section.label}</div>
                <img src={'/img/chevron-'+(openedItems.includes(index) ? 'down' : 'up')+'.svg'}/>
            </div>
            {openedItems.includes(index) ? <div className={'custom-accordion-content'}>
                {section.content}
            </div> : ''}
        </div>)}
    </div>;
}
