import './SelectContacts.css';
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CustomTextInput from "../../../../shared/CustomTextInput/CustomTextInput";
import CustomCheckbox from "../../../../shared/CustomCheckbox/CustomCheckbox";
import {toast} from "react-toastify";

export default function SelectContacts({onComplete, data, setData, onClickBack, dataFromLk}) {
    let [name, setName] = useState(data.name ?? '');
    let [nameIsError, setNameIsError] = useState(false);

    let [lastName, setLastName] = useState(data.lastName ?? '');
    let [lastnameIsError, setLastNameIsError] = useState(false);

    let [phone, setPhone] = useState(data.phone ?? '');
    let [phoneIsError, setPhoneIsError] = useState(false);

    let [email, setEmail] = useState(data.email ?? '');
    let [emailIsError, setEmailIsError] = useState(false);

    let [carNumber, setCarNumber] = useState(data.carNumber ?? '');
    let [carNumberIsError, setCarNumberIsError] = useState(false);

    let [carVin, setCarVin] = useState(data.carVin ?? '');
    let [carVinIsError, setCarVinIsError] = useState(false);

    let [iAgreeForProcessRequest, setIAgreeForProcessRequest] = useState(false);
    let [iAgreeForCommunication, setIAgreeForCommunication] = useState(false);

    let isComplete = () => {
        //debugger
        return name !== '' &&
            lastName !== '' &&
            phone !== '' &&
            phone.indexOf('_') === -1 &&
            email !== '' &&
            carNumber !== '' &&
            carNumber.length >= 6 &&
            iAgreeForProcessRequest && iAgreeForCommunication;
    };
    let isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    useEffect(()=>{
        if(dataFromLk){
            setName(dataFromLk.user.firstName);
            setLastName(dataFromLk.user.lastName);
        }
    },[]);

    let vinDisabled = dataFromLk ?? (data.carVin !== undefined && data.carVin !== null && data.carVin !== '');

    return <div>
        <div className={'for-mobile'}>
            <CustomTextInput
                disabled={dataFromLk ?? false}
                isRequired
                isError={nameIsError}
                value={name}
                placeholder={'Имя'}
                onChange={setName}
                onFocus={()=>{setNameIsError(false)}}
            />
            <CustomTextInput
                disabled={dataFromLk ?? false}
                isRequired
                isError={lastnameIsError}
                value={lastName}
                placeholder={'Фамилия'}
                onChange={setLastName}
                onFocus={()=>{setLastNameIsError(false)}}
            />
            <CustomTextInput
                isRequired
                isError={phoneIsError}
                value={phone}
                placeholder={'Телефон'}
                onChange={setPhone}
                onFocus={()=>{setPhoneIsError(false)}}
                mask={"+7 (999) 999-99-99"}
                pattern={"/^+7\d*"}
            />

            <CustomTextInput
                isRequired
                isError={emailIsError}
                value={email}
                placeholder={'E-mail'}
                onChange={setEmail}
                onFocus={()=>{setEmailIsError(false)}}
            />
            <CustomTextInput
                isRequired
                isError={carNumberIsError}
                value={carNumber}
                placeholder={'Регистрационный номер'}
                onChange={setCarNumber}
                onFocus={()=>{setCarNumberIsError(false)}}
            />

            <CustomTextInput
                disabled={vinDisabled}
                value={carVin}
                isError={carVinIsError}
                placeholder={'VIN'}
                onChange={setCarVin}
                onFocus={()=>{setCarVinIsError(false)}}
            />
        </div>
        <div className={'for-desktop'}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <CustomTextInput
                            disabled={dataFromLk ?? false}
                            isRequired
                            isError={nameIsError}
                            value={name}
                            placeholder={'Имя'}
                            onChange={setName}
                            onFocus={()=>{setNameIsError(false)}}
                        />
                        <CustomTextInput
                            isRequired
                            isError={phoneIsError}
                            value={phone}
                            placeholder={'Телефон'}
                            onChange={setPhone}
                            onFocus={()=>{setPhoneIsError(false)}}
                            mask={"+7 (999) 999-99-99"}
                            pattern={"/^+7\d*"}
                        />
                        <CustomTextInput
                            isRequired
                            isError={carNumberIsError}
                            value={carNumber}
                            placeholder={'Регистрационный номер'}
                            onChange={setCarNumber}
                            onFocus={()=>{setCarNumberIsError(false)}}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <CustomTextInput
                            disabled={dataFromLk ?? false}
                            isRequired
                            isError={lastnameIsError}
                            value={lastName}
                            placeholder={'Фамилия'}
                            onChange={setLastName}
                            onFocus={()=>{setLastNameIsError(false)}}
                        />

                        <CustomTextInput
                            isRequired
                            isError={emailIsError}
                            value={email}
                            placeholder={'E-mail'}
                            onChange={setEmail}
                            onFocus={()=>{setEmailIsError(false)}}
                        />

                        <CustomTextInput
                            disabled={vinDisabled}
                            value={carVin}
                            isError={carVinIsError}
                            placeholder={'VIN'}
                            onChange={setCarVin}
                            onFocus={()=>{setCarVinIsError(false)}}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
        <div className={'required-fields-description'}><span>*</span> отмечены обязательные поля</div>
        <div className={'checkboxs-container'}>
            <CustomCheckbox
                value={iAgreeForProcessRequest}
                onChange={setIAgreeForProcessRequest}
                label={<span>Я согласен(на) на обработку моих персональных данных в соответсвии с <a href={'https://www.chery.ru/chery/about/terms-conditions/policy-personal-data/'} target={'_blank'}>политикой</a></span>}
            />
            <CustomCheckbox
                value={iAgreeForCommunication}
                onChange={setIAgreeForCommunication}
                label={'Я согласен(на) на дальнейшую коммуникацию'}
            />
        </div>
        <Grid style={{marginTop: '176px'}}>
            <Grid.Row className={'actions-container'}>
                <Grid.Column width={8}>
                    <Button onClick={onClickBack}>Вернуться</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    {isComplete() ? <Button onClick={()=>{
                        if(!isValidEmail(email)){
                            toast.error('Укажите корректный E-mail');
                            return;
                        }
                        setData(data => {
                            data.filter(x=> x.name === 'user')[0].subTitle = name + ' ' + lastName;
                            data.name = name;
                            data.lastName = lastName;
                            data.phone = phone;
                            data.email = email;
                            data.carNumber = carNumber;
                            data.carVin = carVin;
                            return data;
                        });
                        onComplete();
                    }}>Продолжить</Button> : <Button disabled>Продолжить</Button>}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>;
}
