import './Finish.css';
import {Button, Grid, TextArea} from "semantic-ui-react";
import {useState} from "react";
import {api} from "../../../../utils/api";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import ru from 'date-fns/locale/ru';
import {toast} from "react-toastify";

export default function Finish({data, setData, onClickBack, onFinish}) {
    let [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [dateDescription, setDateDescription] = useState(null);

    const [comment, setComment] = useState('');

    let createRequest = ()=>{
        let props = {};
        let familyId = data.filter(x=> x.name === 'car')[0].familyId;
        let versionId = data.filter(x=> x.name === 'engine')[0].type;
        props.dealerPointId = '';
        props.clientDescr = data.name + ' ' + data.lastName;
        props.phone = data.phone;
        props.email = data.email;
        props.familyId = familyId;
        props.versionId = versionId;
        props.carTypeId = 201;
        props.dealerPointId = data.dialerId;
        props.licensePlate = data.carNumber;
        props.vin = data.carVin;
        let year = selectedDay.getFullYear();
        let month = selectedDay.getMonth() + 1;
        let date = selectedDay.getDate();
        props.orderDatetime = `${year}-${(month < 10 ? '0' + month: month)}-${(date < 10 ? '0' + date: date)}` + ' ' + selectedTimeSlot;
        props.serviceBookId = data.filter(x=> x.name === 'engine')[0].serviceBookId;
        props.serviceBookColumnId = data.filter(x=> x.name === 'engine')[0].serviceBookColumnId;
        props.price = data.totalPrice;
        props.additionalWorks = data.additionalWorks.filter(x=>x.added).map(x=>x.WorkId).reduce((previousValue, currentValue) => previousValue + (previousValue == '' ? '' : ',') + currentValue, '');

        if(comment !== '')
            props.notes = comment;
        api()
            .createMaintenanceOrder(props)
            .then(()=>{
                onFinish();
            });
    };

    return <div className={'select-time-slot-page'}>
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <div style={{paddingLeft: '20px'}}>Выберите дату</div>
                    <div className={'calendar'}>
                        <DayPicker
                            disabledDays={
                                {
                                    //before: twoMonthsAgo,
                                    after: new Date()
                                }}
                            locale={ru}
                            mode="single"
                            selected={selectedDay}
                            onSelect={(val)=>{
                                let now = new Date();
                                now.setMilliseconds(0);
                                now.setSeconds(0);
                                now.setHours(0);
                                now.setMinutes(0);
                                let before = new Date();
                                before.setDate(before.getDate() + 7);
                                if(val < now)
                                {
                                    toast.error('Выберите дату в будущем');
                                    setSelectedDay(null);
                                    setSelectedTimeSlot(null);
                                    setDateDescription(null);
                                    return;
                                }
                                if(val > before){
                                    toast.error('Выберите дату ранее ' + before.toLocaleDateString());
                                    setSelectedDay(null);
                                    setSelectedTimeSlot(null);
                                    setDateDescription(null);
                                    return;
                                }
                                setSelectedDay(val);
                                setSelectedTimeSlot(null);
                                setDateDescription(null);
                            }}
                        />
                    </div>
                    {selectedDay !== null && selectedTimeSlot !== null ? <div>
                        Дата и время Вашего визита на сервисное обслуживание
                    </div> : ''}
                </Grid.Column>
                <Grid.Column width={8}>
                    {selectedDay != null ? <>
                        <div>Доступные временные интервалы</div>
                        <div className={'time-slots'}>
                            {data.calculation.TimeRecords
                                .filter(x=> {
                                    let today = new Date()
                                    today.setHours(0,0,0,0);
                                    if((today - selectedDay) === 0){
                                        let hours = parseInt(x.split(':')[0]);
                                        let minutes = parseInt(x.split(':')[1]);
                                        let target = new Date();
                                        target.setHours(hours,minutes,0,0);
                                        return new Date() < target;
                                    }else{
                                        return true;
                                    }
                                })
                                .map(timeSlot => <div className={selectedTimeSlot === timeSlot ? 'selected' : ''}
                                                                               onClick={()=>{
                                                                                   setSelectedTimeSlot(timeSlot);
                                                                                   let year = selectedDay.getFullYear();
                                                                                   let month = selectedDay.getMonth() + 1;
                                                                                   let date = selectedDay.getDate();

                                                                                   let dateStr = `${(date < 10 ? '0' + date: date)}.${(month < 10 ? '0' + month: month)}.${year}`;
                                                                                   let dayName = selectedDay.getDay();
                                                                                   switch (selectedDay.getDay()) {
                                                                                       case 0:
                                                                                           dayName = "Воскресенье";
                                                                                           break;
                                                                                       case 1:
                                                                                           dayName = "Понедельник";
                                                                                           break;
                                                                                       case 2:
                                                                                           dayName = "Вторник";
                                                                                           break;
                                                                                       case 3:
                                                                                           dayName = "Среда";
                                                                                           break;
                                                                                       case 4:
                                                                                           dayName = "Четверг";
                                                                                           break;
                                                                                       case 5:
                                                                                           dayName = "Пятница";
                                                                                           break;
                                                                                       case 6:
                                                                                           dayName = "Суббота";
                                                                                           break;
                                                                                   }
                                                                                   let s = dayName.toUpperCase() + ', ' + dateStr + ' ' + timeSlot.substr(0, 5);
                                                                                   setDateDescription(s);
                                                                                   setData(data => {
                                                                                       data.date = s;
                                                                                       return data
                                                                                   });
                                                                               }}>{timeSlot.substr(0, 5)}</div>)}
                        </div>
                        <div>
                            <TextArea
                                value={comment}
                                onChange={e=>{setComment(e.target.value)}}
                                className={'comment-input'}
                                placeholder={'Ваш комментарий'}
                            ></TextArea>
                        </div>
                        <span className={'text-date-description'}>{dateDescription}</span>
                    </> : ''}
                </Grid.Column>
            </Grid.Row>
        </Grid>
        {selectedDay !== null && selectedTimeSlot !== null ? <button className={'create-request'} onClick={createRequest}>записаться на сервис</button> : ''}
        <Grid.Row className={'actions-container'}>
            <Grid.Column width={8}>
                <Button onClick={onClickBack}>Вернуться</Button>
            </Grid.Column>
            <Grid.Column width={8}>
            </Grid.Column>
        </Grid.Row>
    </div>;
}
