import './Menu.css';
import {Container, Grid} from "semantic-ui-react";
import {useSearchParams} from "react-router-dom";

export default function Menu() {
    const [searchParams] = useSearchParams();
    let data = searchParams.get("data")

    if(data)
        return <></>;
    return <div className={'header-container'}>
        <Container>
            <Grid className={'for-desktop'}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <a href={'https://chery.ru'}><img className={'logo'} src={'/img/chery_logo.png'}/></a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div className={'for-mobile'} style={{textAlign: 'center'}}>
                <a href={'https://chery.ru'}><img style={{position: 'relative'}} className={'logo'} src={'/img/chery_logo.png'}/></a>
            </div>
        </Container>
    </div>;
}
