import './ServiceCalculator.css';
import {Container, Grid} from "semantic-ui-react";
import {useState} from "react";
import SelectCarModel from "./components/SelectCarModel/SelectCarModel";
import SelectEngine from "./components/SelectEngine/SelectEngine";
import SelectServiceLocation from "./components/SelectServiceLocation/SelectServiceLocation";
import SelectCost from "./components/SelectCost/SelectCost";
import SelectContacts from "./components/SelectContacts/SelectContacts";
import Finish from "./components/Finish/Finish";
import {useSearchParams} from "react-router-dom";

export default function ServiceCalculator() {
    let [activeMenuItem, setActiveMenuItem] = useState(0);
    let initialState = [
        {name:'car', description: 'Ваш автомобиль', mainTitle: 'Выберите Ваш автомобиль', subTitle: ''},
        {name:'engine', description: 'Двигатель и трансмиссия', mainTitle: 'Укажите тип двигателя и трансмиссии', subTitle: '', isLarge: true, type: '', age: 0, mileage: 0},
        {name:'map-pin-menu', description: 'Сервисный центр', mainTitle: 'Выберите сервисный центр', subTitle: ''},
        {name:'money', description: 'Расчет стоимости', mainTitle: 'Делаем расчёт стоимости работ и запасных частей', subTitle: ''},
        {name:'user', description: 'Контактные данные', mainTitle: 'Заполните контактные данные', subTitle: ''},
        {name:'calendar', description: 'Запись на сервис', mainTitle: 'Выберете дату и время для записи', subTitle: ''},
    ];
    let [wayPoints, setWayPoints] = useState(initialState);
    let [isFinish, setIsFinish] = useState(false);
    const [searchParams] = useSearchParams();
    let data = searchParams.get("data");
    if(data)
        data = JSON.parse(data);
    function getPage(name) {
        switch (name) {
            case 'car':
                return <SelectCarModel
                    data={wayPoints}
                    setData={setWayPoints}
                    onComplete={()=>{setActiveMenuItem(1)}}
                    dataFromLk={data}
                />;
            case 'engine':
                return <SelectEngine
                    data={wayPoints}
                    setData={setWayPoints}
                    onComplete={()=>{setActiveMenuItem(2)}}
                    onClickBack={()=>{setActiveMenuItem(0)}}
                    dataFromLk={data}
                />;
            case 'map-pin-menu':
                return <SelectServiceLocation
                    data={wayPoints}
                    setData={setWayPoints}
                    onComplete={()=>{setActiveMenuItem(3)}}
                    onClickBack={()=>{setActiveMenuItem(1)}}
                    dataFromLk={data}
                />;
            case 'money':
                return <SelectCost data={wayPoints} setData={setWayPoints}
                                   onComplete={()=>{setActiveMenuItem(4)}}
                                   onClickBack={()=>{setActiveMenuItem(2)}}
                />;
            case 'user':
                return <SelectContacts data={wayPoints} setData={setWayPoints}
                                       onComplete={()=>{setActiveMenuItem(5)}}
                                       onClickBack={()=>{setActiveMenuItem(3)}}
                                       dataFromLk={data}
                />;
            case 'calendar':
                return <Finish data={wayPoints}
                               setData={setWayPoints}
                               onClickBack={()=>{setActiveMenuItem(4)}}
                               onFinish={()=>{setIsFinish(true)}}
                />;
            default:
                return <SelectCarModel data={wayPoints}
                                       setData={setWayPoints}
                                       onComplete={()=>{setActiveMenuItem(1)}}
                />;
        }
    }

    return <div className={'service-calculator' + (data ? ' without-menu' : '')}>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} className={'menu-items-container'}>
                  {wayPoints.map((wayPoint, index) =>
                      <div className={'menu-item'}>
                          <div
                              className={'menu-item-image-container' + (index === activeMenuItem ? ' active' : '') +
                                  (index < activeMenuItem ? ' is-complete' : '')} onClick={()=>{
                                      if(isFinish)
                                          return;
                                      if(index < activeMenuItem)
                                            setActiveMenuItem(index);
                          }}>
                              {index >= activeMenuItem ? <img
                                  src={'/img/' + wayPoint.name + (index === activeMenuItem ? '_active' : '') + '.svg'}/> : ''}

                              {index < activeMenuItem ? <img src={'/img/menu-check.svg'}/> : ''}
                          </div>
                          <div className={'menu-item-description-container for-desktop'}>
                              <div className={'title'}>Шаг {index + 1}</div>
                              <div className={'description'}>{wayPoint.description}</div>
                              {wayPoint.subTitle !== '' ? <div className={'selected'}>{wayPoint.subTitle}</div> : ''}
                          </div>
                          {index === (wayPoints.length - 1) ? '' :
                              <div
                                  style={{height: wayPoint.isLarge ? '70px' : '48px'}}
                                  className={'for-desktop string' + (index === activeMenuItem ? ' active' : '') + (index < activeMenuItem ? ' is-complete' : '')}></div>}
                          {index === (wayPoints.length - 1) ? '' :
                              <div
                                  style={{height: '48px'}}
                                  className={'for-mobile string' + (index === activeMenuItem ? ' active' : '') + (index < activeMenuItem ? ' is-complete' : '')}></div>}
                      </div>
                  )}
              </Grid.Column>
              <Grid.Column width={13} className={'main-content-container'}>
                  <div className={'main-title'}>{isFinish ? 'Спасибо!' : wayPoints[activeMenuItem].mainTitle}</div>
                  {isFinish ? <div className={'finish-block'}>
                      <div className={'image-container'}><img src={'/img/is-send.png'}/></div>
                      <div className={'title'}>Вы записаны на сервисное обслуживание</div>
                      <div className={'dialer-info'}>Дилер: {wayPoints.filter(x=>x.name == 'map-pin-menu')[0].subTitle}</div>
                      <div className={'date-info'}>Дата, время: <b>{wayPoints.date}</b></div>
                      <div className={'car-info'}>Автомобиль: {wayPoints.filter(x=>x.name == 'car')[0].subTitle}</div>
                      <div className={'info-message'}><b>Данная информация направлена Вам на указанный E-mail</b></div>
                      {data ? <div>
                          <p>Отменить запись можно в разделе "Запись на сервис"</p>
                      </div> : <div className={'action-container'}><button onClick={()=>{
                          setActiveMenuItem(0);
                          setWayPoints(initialState);
                          setIsFinish(false);
                      }}>вернуться на главную</button></div>}
                  </div>: <div>
                      {getPage(wayPoints[activeMenuItem].name)}
                  </div>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
    </div>;
}
