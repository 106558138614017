import './SelectServiceLocation.css';
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {Map, Placemark, YMaps} from 'react-yandex-maps';
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";

export default function SelectServiceLocation({onComplete, data, setData, onClickBack, dataFromLk}) {
    let [activeDialerIdOnMap, setActiveDialerIdOnMap] = useState(data.dialerId ?? null);
    let [viewList, setViewList] = useState(false);
    let [mapCenter, setMapCenter] = useState([55.75, 37.57]);
    let [viewMap, setViewMap] = useState(true);
    let [search, setSearch] = useState('');

    let [dealers, setDealers] = useState([{id: 1, name: 'АвтоГЕРМЕС', coord1: 55.75, coord2: 37.57}]);
    let [isLoading, setIsLoading] = useState(false);

    let cities = [
        {name: 'Москва', coord: [55.75, 37.57]},
        {name: 'Санкт-Петербург', coord: [59.93, 30.31]},
        {name: 'Казань', coord: [55.78, 49.12]},
        {name: 'Сочи', coord: [43.60, 39.73]},
        {name: 'Екатеринбург', coord: [56.83, 60.61]},
        {name: 'Ростов-на-Дону', coord: [47.23, 39.70]},
    ];

    useEffect(()=>{
        setIsLoading(true);
        api()
            .getDealers()
            .then(resp=>{
                let serviceBookId = data.filter(x=> x.name === 'engine')[0].serviceBookId;
                let serviceBookColumnId = data.filter(x=> x.name === 'engine')[0].serviceBookColumnId;

                api().getMaintenancePrice(data.filter(x=> x.name === 'car')[0].familyId,
                    data.filter(x=> x.name === 'engine')[0].type,
                    serviceBookId, serviceBookColumnId)
                    .then(maintenancePriceForDealer=>{
                        setIsLoading(false);
                        setDealers(resp.map(d => {
                            let filterElement = maintenancePriceForDealer.filter(x=>x.DealerPointId === d.DealerPointId)[0];
                            return {
                                id: d.DealerPointId,
                                name: d.Descr,
                                address: d.Address,
                                city: d.City,
                                price: filterElement !== undefined ? filterElement.Price : 0,
                                coord: [d.MapCoord1, d.MapCoord2],
                                inSearch: true,
                            };
                        }));
                        //debugger
                        if(dataFromLk && resp.filter(x=>x.DealerId === dataFromLk.user.dealerId).length > 0){
                            let id = resp.filter(x=>x.DealerId === dataFromLk.user.dealerId)[0].DealerPointId;
                            setActiveDialerIdOnMap(id);
                        }
                    });
            });
    }, []);

    useEffect(()=>{
        let isChange = false;

        const trimmedSearchString = search.trim()

        let temp = dealers.map(d => {
            if (d && d.city) {
                let old = d.inSearch

                d.inSearch = d.name.toLowerCase().indexOf(trimmedSearchString.toLowerCase()) !== -1 ||
                    d.address.toLowerCase().indexOf(trimmedSearchString.toLowerCase()) !== -1 ||
                    d.city.toLowerCase().indexOf(trimmedSearchString.toLowerCase()) !== -1;

                if (isChange === false && old !== d.inSearch) {
                    isChange = true
                }
            }

            return d;
        });

        setDealers(temp);

        if(isChange){
            let inSearchItems = temp.filter(x=>x.inSearch && x.coord !== undefined && x.coord[0] !== 0 && x.coord[1] !== 0);
            if(inSearchItems.length > 0) {
                let minX = Math.min(...(inSearchItems.map(x=>x.coord[0])));
                let minY = Math.min(...(inSearchItems.map(x=>x.coord[1])));
                let maxX = Math.max(...(inSearchItems.map(x=>x.coord[0])));
                let maxY = Math.max(...(inSearchItems.map(x=>x.coord[1])));
                let centerX = minX + (maxX - minX)/2;
                let centerY = minY + (maxY - minY)/2;
                if(centerX != NaN &&
                    centerY != NaN &&
                    centerX > -180 &&
                    centerX < 180 &&
                    centerY > -180 &&
                    centerY < 180) {
                    setMapCenter([centerX, centerY]);
                }
            }
            setViewMap(false);
            setTimeout(()=>{setViewMap(true);}, 0);
        }
    }, [search]);

    const getMyPosition = () => {
        navigator
            .geolocation
            .getCurrentPosition(
                position => {
                    setMapCenter([position.coords.latitude, position.coords.longitude]);
                    setViewMap(false);
                    setTimeout(()=>{setViewMap(true);}, 0);
                },
                err => {
                    toast.error("Не удалось получить местоположение");
                }
            );
    };

    const handleChangeSearch = (event) => {
        setSearch(event.target.value)
    }

    return <div>
        <Grid className={'search-row-container'}>
            <Grid.Row>
                <Grid.Column width={12}>
                    <input className={'search-on-map-input'} value={search} onChange={handleChangeSearch} placeholder={'Город или название дилера'}/>
                </Grid.Column>
                <Grid.Column width={4}>
                    <button className={'map-switcher-btn' + (viewList ? ' active' : '')} onClick={()=> setViewList(!viewList)}>
                        <span className={'for-desktop'}>{viewList ? 'На карте' : 'Списком'}</span>
                        <img className={'for-mobile'} src={'/img/list-on-map'+(viewList ? '-active' : '')+'.svg'}/>
                    </button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <div className={'city-examples-block'}>
            <div className={'city-examples-block-inner'}>
                {cities.map(city =><div className={'city-example-item'} onClick={()=>{
                    if(isLoading)
                        return;
                    setMapCenter(city.coord);
                    setViewMap(false);
                    setTimeout(()=>{setViewMap(true);}, 0);
                    setSearch(city.name);
                }}>{city.name}</div>)}
            </div>
        </div>
        <div className={'map-container-block'}>
            {viewMap ? <div>
                <YMaps style={{width: '100%'}}>
                    <Map height={'350px'} width={window.innerWidth < 700 ? '300px' : '880px'} defaultState={{ center: mapCenter, zoom: 9 }}>
                        {dealers.filter(x=> x.price !== undefined && x.price !== null && x.price !== 0 && x.inSearch).map((d, i) => <Placemark
                            key={'Placemark' + i}
                            defaultGeometry={d.coord}
                            modules={[
                                'geoObject.addon.balloon',
                                'geoObject.addon.hint'
                            ]}
                            properties={{
                                balloonContent: '<div>'+d.name+'</div><div>'+d.price.toLocaleString(undefined, {
                                    maximumFractionDigits: 2
                                })+' ₽</div><div>'+d.address+'</div>',
                            }}
                            options={{
                                iconLayout: "default#image",
                                iconImageHref: activeDialerIdOnMap === d.id ? '/img/active-map-pin.png' : '/img/map-pin.png',
                                iconImageSize: [28, 45],
                            }}
                            onClick={()=>{
                                if(activeDialerIdOnMap === d.id)
                                    setActiveDialerIdOnMap(null);
                                else
                                    setActiveDialerIdOnMap(d.id);
                            }}
                        />)}
                    </Map>
                </YMaps>
                <img className={'get-my-coords'} src={'/img/get-my-coords.svg'} onClick={getMyPosition}/>
            </div> : ''}
            {viewList ? <div className={'dealer-list-block'}>
                {dealers.filter(x=> x.price !== 0 && x.inSearch).length === 0 ? <div className={'dealers-not-found-message'}>
                    Нет дилеров для отображения
                </div> : ''}
                {dealers.filter(x=> x.price !== 0 && x.inSearch).map(dealer => <div className={'dealer-item'}
                                                                          onClick={()=>{
                                                                              setActiveDialerIdOnMap(val=>{return dealer.id === val ? null : dealer.id});
                                                                          }}>
                    <img className={'select-indicator'} src={'/img/radiobutton'+
                        (dealer.id === activeDialerIdOnMap ? '_active' : '') +'.svg'} />
                    <span className={'name'}>{dealer.name}</span>
                    <span className={'address'}>{dealer.address}</span>
                    <span className={'price'}>{dealer.price.toLocaleString(undefined, {
                        maximumFractionDigits: 2
                    })} ₽</span>
                </div>)}
            </div> : ''}
            {isLoading ? <div className={'loader-map'}>Загрузка...</div> : ''}
        </div>
        {isLoading ? '' : <Grid>
            <Grid.Row className={'actions-container'}>
                <Grid.Column width={8}>
                    <Button onClick={onClickBack}>Вернуться</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    {activeDialerIdOnMap !== null && dealers.filter(x=>x.id === activeDialerIdOnMap).length > 0 && dealers.filter(x=>x.id === activeDialerIdOnMap)[0].inSearch ? <Button onClick={()=>{
                        setData(data => {
                            data.filter(x=> x.name === 'map-pin-menu')[0].subTitle = dealers.filter(x=>x.id === activeDialerIdOnMap)[0].name;
                            return data;
                        });
                        let serviceBookId = data.filter(x=> x.name === 'engine')[0].serviceBookId;
                        let serviceBookColumnId = data.filter(x=> x.name === 'engine')[0].serviceBookColumnId;
                        api().getMaintenancePrice(data.filter(x=> x.name === 'car')[0].familyId,
                            data.filter(x=> x.name === 'engine')[0].type,
                            serviceBookId, serviceBookColumnId)

                        let familyId = data.filter(x=> x.name === 'car')[0].familyId;
                        let versionId = data.filter(x=> x.name === 'engine')[0].type;
                        api()
                            .getAdditionalWorks(familyId,
                                versionId,
                                activeDialerIdOnMap,
                                serviceBookId, serviceBookColumnId)
                            .then(additionalWorks => {
                                data.filter(x=> x.name === 'map-pin-menu')[0].additionalWorks = additionalWorks;
                                api()
                                    .getCalculation(familyId,
                                        versionId,
                                        activeDialerIdOnMap,
                                        serviceBookId, serviceBookColumnId, additionalWorks.map(x=>x.WorkId).reduce((previousValue, currentValue) => previousValue + ',' + currentValue, ''))
                                    .then(calculation=>{
                                        setData(data=>{
                                            data.additionalWorks = additionalWorks
                                                .map(x=> {
                                                    x.added = false;
                                                    return x;
                                                });
                                            data.calculation = calculation;
                                            data.dialerId = activeDialerIdOnMap;
                                            return data;
                                        });
                                        onComplete();
                                    });
                            });
                    }}>Продолжить</Button> : ''}
                </Grid.Column>
            </Grid.Row>
        </Grid>}

    </div>;
}
