import './SelectCarModel.css';
import {Button, Grid} from "semantic-ui-react";
import {useEffect, useState} from "react";
import CarItem from "../../../../shared/CarItem/CarItem";
import CustomTextInput from "../../../../shared/CustomTextInput/CustomTextInput";
import {api} from "../../../../utils/api";
import {toast} from "react-toastify";

export default function SelectCarModel({onComplete, data, setData, dataFromLk}) {
    let [vin, setVin] = useState(data.carVin ?? '');
    let [vinIsError, setVinIsError] = useState(false);
    let [isSearchedByVin, setIsSearchedByVin] = useState(!!data.carVin);
    let [models, setModels] = useState([]);

    useEffect(()=>{
        api()
            .getCarFamilyAndVersionList()
            .then(resp => {
                setModels(resp.map(x=> ({
                    name: x.Descr,
                    imageUrl: x.ImageUrl,
                    familyId: x.FamilyId,
                    carVersions: x.CarVersions,
                    color: 'background: linear-gradient(116.72deg, #492938 0%, #A08F97 63.02%)'
                })));
            });
    }, []);

    let onBeforeComplete = (car, vinFromLk, numberFromLk)=> {
        setData(data => {
            let oldCarName = data.filter(x=> x.name === 'car')[0].subTitle;
            data.filter(x=> x.name === 'car')[0].subTitle = car.name;
            data.filter(x=> x.name === 'car')[0].color = car.color;
            data.filter(x=> x.name === 'car')[0].familyId = car.familyId;
            data.filter(x=> x.name === 'car')[0].imageUrl = car.imageUrl;
            data.filter(x=> x.name === 'car')[0].carVersions = car.carVersions;

            if(oldCarName === car.name){

            }else{
                data.filter(x=> x.name === 'engine')[0].type = '';
                data.carVin = null;
            }

            if(dataFromLk){
                data.carVin = vinFromLk;
                data.carNumber = numberFromLk;
            }else{
                if(oldCarName === car.name){
                    data.carVin = vin;
                }
            }
            return data;
        })
        onComplete();
    };

    if(dataFromLk !== null)
        return <div className={'cars-container'}>
            {models.length > 0 ? <>
                {dataFromLk.cars.map(car => {
                    let selectedCar = models.filter(x=>x.familyId === car.familyId)[0];

                    if (selectedCar) {
                        return <div
                            className={'car-from-lk-container'}
                            style={{}}
                            onClick={()=>{
                                onBeforeComplete(selectedCar, car.vin, car.number);
                            }}
                        >
                            <div>{car.name}</div>
                            <div className={'vin'}>{car.vin}</div>
                            <img className={'car-image'} src={selectedCar.imageUrl}/>
                        </div>;
                    }

                })}
            </>: ''}
        </div>;

    return <div className={'cars-container'}>
        <div style={{position: 'relative'}}>
            <CustomTextInput
                isError={vinIsError}
                value={vin}
                placeholder={'Укажите VIN Вашего автомобиля'}
                onChange={(val)=>{
                    if(val.length >= 6 || val.length <= 17){
                        api()
                            .getCarInfo(val)
                            .then(resp => {
                                if(resp.ErrorMessage === null){
                                    let model = models.filter(m => {return m.familyId === resp.FamilyId})[0];
                                    if(model === undefined){
                                        toast.error(`Для указанного VIN не предусмотрена запись на ТО`);
                                    }else{
                                        setData(data => {
                                            data.filter(x=> x.name === 'car')[0].subTitle = model.name;
                                            data.filter(x=> x.name === 'car')[0].color = model.color;
                                            data.filter(x=> x.name === 'car')[0].familyId = resp.FamilyId;
                                            debugger
                                            data.filter(x=> x.name === 'engine')[0].type = resp.VersionId;
                                            data.filter(x=> x.name === 'car')[0].imageUrl = model.imageUrl;
                                            data.filter(x=> x.name === 'car')[0].carVersions = resp.CarVersions;
                                            return data;
                                        });
                                        setIsSearchedByVin(true);
                                        if(val.length !== 17)
                                            setVin(resp.Vin);
                                    }
                                }
                            })
                    }
                    setVin(val);
                }}
                onFocus={()=>{setVinIsError(false)}}
            />
            <span style={{position: 'absolute', top: '60px', left: '0px', color: '#A6A6A6'}}>Вы можете указать <b>последние 6 цифр</b></span>
        </div>

        {models.map(car => <CarItem imageUrl={car.imageUrl} name={car.name} color={car.color} onClick={()=>{
            onBeforeComplete(car);
        }}
        isMarked={data.filter(x=> x.name === 'car')[0].subTitle === car.name}
        />)}

        <Grid.Row className={'actions-container'}>
            <Grid.Column width={8}>
            </Grid.Column>
            <Grid.Column width={8}>
                {isSearchedByVin ?
                    <Button onClick={()=>{
                        let car = models.filter(car=> data.filter(x=> x.name === 'car')[0].subTitle === car.name)[0];
                        onBeforeComplete(car);
                    }}>Продолжить</Button> : ''}
            </Grid.Column>
        </Grid.Row>

    </div>;
}
