import './CarItem.css';

export default function CarItem({name, color, onClick, isMarked, imageUrl}) {
    return <div className={'car-placeholder'}
                style={{background: color}}
                onClick={onClick}>
        {name}
        <img className={'car-image'} src={imageUrl}/>
        {isMarked ? <div className={'marker'}/> : ''}
    </div>;
}