import './CustomSlider.css';
import {useEffect, useState} from "react";
import Range  from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function CustomSlider({label, initValue, maxValue, step, onChange}) {
    let [value, setValue] = useState(initValue);
    let [scale, setScale] = useState([]);

    useEffect(()=>{
        let scalesCount = maxValue/step;
        let temp = [];
        for (let i = 0; i <= scalesCount; i++)
            temp.push({value: i*step});
        setScale(temp);
    },[]);
    return <div className={'custom-slide-container'}>
        <div className={'title'}>{label}</div>
        <Range
            range={true}
            value={value}
            max={maxValue}
            pushable={2}
            step={step}
            defaultValue={initValue}
            onAfterChange={(params)=>{
                onChange(params[0])
            }}
            onChange={(params)=>{
                setValue(params[0]);
            }}
        />
        <div className={'scale'}>
            {scale.map(x=><div style={{width: (100/(maxValue/step) * 0.9) + '%'}} className={'scale-item'}>
                <div className={'content'} style={{left: '-' + (x.value < 10 ? '4' : (x.value < 100 ? '8' : '12')) + 'px'}}>{x.value}</div>
            </div>)}
        </div>
    </div>;
}
